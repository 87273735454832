const jsonParse = function (name = null) {
	try {
		if(!name) throw new Error('name is not defined')
		
		return JSON.parse(name)
	} catch (e) {
		console.debug(`[jsonParse env name: "${name}"]`, e)
		
		throw e
	}
}

export const getAsBool = function (name = null) {
	if (!name) return undefined

	return jsonParse(process.env?.[name]?.toLowerCase() || 'false') === true
}

export const getAsNumber = function (name = null) {
	return getAsInt(name)
}

export const getAsInt = function (name = null) {
	if (!name) return undefined

	return parseInt(jsonParse(process.env?.[name] || '0'))
}

export const getAsFloat = function (name = null) {
	if (!name) return undefined

	return parseFloat(jsonParse(process.env?.[name] || '0'))
}

export const getAsDate = function (name = null) {
	if (!name) return undefined

	if (/^[0-9]{4}[-][0-9]{2}[-][0-9]{2}$/g.test(process.env?.[name]))
		return process.env?.[name]

	return null
}

export const getAsDateTime = function (name = null) {
	if (!name) return undefined

	if (
		/^[0-9]{4}[-][0-9]{2}[-][0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}$/g.test(
			process.env?.[name]
		)
	)
		return process.env?.[name]

	return null
}

export const getAsString = function (name = null) {
	if (!name) return undefined

	const s = process.env?.[name]
	if (s && s?.length > 0)
		return process.env?.[name]

	return null
}

export const getAsArray = function (name = null) {
	if (!name) return undefined

	const s = process.env?.[name]
	if (s && s?.length > 0)
		return s.split(',')

	return null
}

export const isEnvDevelopment = function () {
	return process.env.NODE_ENV === 'development'
}

export const branchBaseName = function () {
	const fullName = getAsString('VUE_APP_BRANCH')
	
	if(!fullName)
		throw new Error('VUE_APP_BRANCH is not defined')
		
	const name = fullName.split('/').pop()
	
	return name
}