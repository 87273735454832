import { couch_uuid, id } from './fields'

export const schema = {
	title: 'stay note schema',
	description: 'describes a single stay note',
	version: 0,
	keyCompression: false,
	primaryKey: 'couch_uuid',
	type: 'object',
	properties: {
		couch_uuid,
		date_created: {
			type: 'string',
			final: true,
		},
		
		type: {
			type: 'string',
		},
		text: {
			type: 'string',
		},

		// persistent id
		stay_id_ref: {
			...id,
		},
		person_id_ref: {
			...id,
		},

		// other expressionfields
		person_first_name: {
			type: 'string',
		},
		person_last_name: {
			type: 'string',
		},
		person_role: {
			type: 'string',
		},
	},
	required: ['couch_uuid'],
}

export default schema
