import { couch_uuid, id, int_field, double_field } from './fields'

export const schema = {
	title: 'zwr patient alert',
	description: 'describes a zwr patient alerts',
	version: 0,
	keyCompression: false,
	primaryKey: 'couch_uuid',
	type: 'object',
	properties: {
		couch_uuid,
		id: { ...id },
		creation_date: {
			type: 'string',
			final: true,
		},
		patient_uuid: {
			...couch_uuid,
		},
		examination_date: {
			type: 'string',
		},
		news2_current_value: {
			...int_field,
		},
		news2_arrow: {
			type: 'string',
		},
		news2_color: {
			type: 'string',
		},
		temp_current_value: {
			...double_field,
		},
		temp_arrow: {
			type: 'string',
		},
		temp_color: {
			type: 'string',
		},
		qsofa_current_value: {
			...int_field,
		},
		qsofa_arrow: {
			type: 'string',
		},
		qsofa_color: {
			type: 'string',
		},
		qsofa_hint: {
			type: 'string',
		},
		is_active: {
			type: 'boolean',
		},
	},
	required: ['couch_uuid', 'stay_id_ref'],
	indexes: [['patient_uuid']],
}

export default schema
