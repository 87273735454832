import { $classes } from '@/main'
import store from '@/store'

import { isOnline } from '@/functions/network'

import { getFormattedDate } from '@/functions/params'

let _getAuthSSPromise = null
let _getAuthOAPromise = null

const _getAuthSS = async function () {
	await isOnline()

	await store.dispatch('CurrentUser/requirePublicClasses')
	await store.dispatch('CurrentUser/requireAuthenticatedClasses')

	if (
		$classes?.Person &&
		!$classes?.Person?.get_app_azure_token_speech_service
	)
		throw new Error('[getAuthSS] no get_app_azure_token_speech_service')

	if (!$classes?.Person?.get_app_azure_token_speech_service)
		throw new Error('[getAuthSS] no classes')

	const auth = await $classes.Person.get_app_azure_token_speech_service()

	if (!auth.token) throw new Error('No token')

	return auth
}

export const getAuthSS = async function () {
	if (_getAuthSSPromise) return await _getAuthSSPromise
	else {
		try {
			_getAuthSSPromise = _getAuthSS()
			const auth = await _getAuthSSPromise
			_getAuthSSPromise = null
			return auth
		} catch (e) {
			_getAuthSSPromise = null
			throw e
		}
	}
}

const _getAuthOA = async function () {
	await isOnline()

	await store.dispatch('CurrentUser/requirePublicClasses')
	await store.dispatch('CurrentUser/requireAuthenticatedClasses')

	if ($classes?.Person && !$classes?.Person?.get_app_azure_token_open_ai)
		throw new Error('[getAuthOA] no get_app_azure_token_open_ai')

	if (!$classes?.Person?.get_app_azure_token_open_ai)
		throw new Error('[getAuthOA] no classes')

	const auth = await $classes.Person.get_app_azure_token_open_ai()

	if (!auth.token) throw new Error('No token')
	else if (!auth.endpoint) throw new Error('No token')

	return auth
}

export const getAuthOA = async function () {
	if (_getAuthOAPromise) return await _getAuthOAPromise
	else {
		try {
			_getAuthOAPromise = _getAuthOA()
			const auth = await _getAuthOAPromise
			_getAuthOAPromise = null
			return auth
		} catch (e) {
			_getAuthOAPromise = null
			throw e
		}
	}
}

export const getSpeechSDK = () => {
	// const { SpeechSDK } = window
	// if(SpeechSDK)
	//     return SpeechSDK

	const sdk = require('microsoft-cognitiveservices-speech-sdk')
	if (sdk) return sdk

	console.warn('Unable to get a SpeechSDK')
	// throw new Error('Unable to get a SpeechSDK')
}

export const getAudioConfigForRealTimeMicrophoneRecognition = (deviceId=null) => {
	const SpeechSDK = getSpeechSDK()

	if(deviceId)
		return SpeechSDK.AudioConfig.fromDefaultMicrophoneInput(deviceId)

	return SpeechSDK.AudioConfig.fromDefaultMicrophoneInput()
}

export const getAudioConfigForRealTimeStreamRecognition = (stream=null) => {
	const SpeechSDK = getSpeechSDK()

	if(stream)
		return SpeechSDK.AudioConfig.fromStreamInput(stream);

	throw new Error('[getAudioConfigForRealTimeStreamRecognition] No stream')
}

export const getSpeechConfig = (token, region) => {
	const SpeechSDK = getSpeechSDK()

	let speechConfig = SpeechSDK.SpeechConfig.fromAuthorizationToken(
		token,
		region
	)
	speechConfig.speechRecognitionLanguage = 'pl-PL'

	return speechConfig
}

export const defaultRecognizing = (s, e) => {
		console.log(`RECOGNIZING: Text=${e.result.text}`)
		store.dispatch('CurrentSTT/updateRecognizingResult', e.result.text)
	},
	defaultRecognized = (s, e) => {
		if (isMatch(e)) {
			console.log(`RECOGNIZED: Text=${e.result.text}`)
			store.dispatch('CurrentSTT/updateRecognizedResult', e.result.text)
		} else if (isNoMatch(e)) {
			console.log('NOMATCH: Speech could not be recognized.')
		}
	},
	defaultCanceled = (s, e) => {
		console.debug(`[defaultCanceled]`)
		/* 
			e.reason
			
			Error = 0	
			Indicates that an error occurred during speech recognition.
			
			EndOfStream = 1	
			Indicates that the end of the audio stream was reached.
		*/
		
		/* 
			e.errorCode
			
			NoError = 0	
			Indicates that no error occurred during speech recognition.
			
			AuthenticationFailure = 1	
			Indicates an authentication error.
			
			BadRequestParameters = 2	
			Indicates that one or more recognition parameters are invalid.
			
			TooManyRequests = 3	
			Indicates that the number of parallel requests exceeded the number of allowed concurrent transcriptions for the subscription.
			
			ConnectionFailure = 4	
			Indicates a connection error.
			
			ServiceTimeout = 5	
			Indicates a time-out error when waiting for response from service.
			
			ServiceError = 6	
			Indicates that an error is returned by the service.
			
			RuntimeError = 7	
			Indicates an unexpected runtime error.
			
			Forbidden = 8	
			Indicates an quota overrun on existing key.
		*/
		
		console.debug(e)

		if (isCanceled(e)) {
			console.error(
				`CANCELED: ErrorCode=${e.errorCode}, ErrorDetails=${e.errorDetails}`
			)
		}
	},
	defaultSpeechEndDetected = () => {
		console.log('\n    speechEndDetected event.')
	},
	defaultSpeechStartDetected = () => {
		console.log('\n    speechStartDetected event.')
	},
	defaultSessionStarted = () => {
		console.log('\n   Session started event.')
	}

export const getSpeechRecognizer = (speechConfig, audioConfig) => {
	const SpeechSDK = getSpeechSDK()

	let speechRecognizer = new SpeechSDK.SpeechRecognizer(
		speechConfig,
		audioConfig
	)

	speechRecognizer.recognizing = defaultRecognizing
	speechRecognizer.recognized = defaultRecognized

	speechRecognizer.canceled = (s, e) => {
		defaultCanceled(s, e)

		speechRecognizer?.stopContinuousRecognitionAsync()
	}

	// eslint-disable-next-line no-unused-vars
	speechRecognizer.sessionStopped = (s, e) => {
		console.log('\n    Session stopped event.')
		speechRecognizer?.stopContinuousRecognitionAsync()
	}

	speechRecognizer.sessionStarted = defaultSessionStarted
	speechRecognizer.speechEndDetected = defaultSpeechEndDetected
	speechRecognizer.speechStartDetected = defaultSpeechStartDetected

	/* 
        Starts speech recognition, and stops after the first utterance is recognized. 
        The task returns the recognition text as result. Note: RecognizeOnceAsync() 
        returns when the first utterance has been recognized, so it is suitable only for 
        single shot recognition like command or query. For long-running recognition, use 
        StartContinuousRecognitionAsync() instead.
    */
	// speechRecognizer.recognizeOnceAsync(result => {
	//     switch (result.reason) {
	//         case SpeechSDK.ResultReason.RecognizedSpeech:
	//             console.log(`RECOGNIZED: Text=${result.text}`);
	//             break;
	//         case SpeechSDK.ResultReason.NoMatch:
	//             console.log("NOMATCH: Speech could not be recognized.");
	//             break;
	//         case SpeechSDK.ResultReason.Canceled:
	//             // eslint-disable-next-line no-case-declarations
	//             const cancellation = SpeechSDK.CancellationDetails.fromResult(result);
	//             console.log(`CANCELED: Reason=${cancellation.reason}`);

	//             if (cancellation.reason == SpeechSDK.CancellationReason.Error) {
	//                 console.log(`CANCELED: ErrorCode=${cancellation.ErrorCode}`);
	//                 console.log(`CANCELED: ErrorDetails=${cancellation.errorDetails}`);
	//                 console.log("CANCELED: Did you set the speech resource key and region values?");
	//             }
	//             break;
	//     }
	//     speechRecognizer.close();
	// });

	// console.debug(speechRecognizer)

	return speechRecognizer
}

export const isMatch = (e = null) => {
	const SpeechSDK = getSpeechSDK()
	if (e && e?.result?.reason == SpeechSDK.ResultReason.RecognizedSpeech)
		return true
}

export const isNoMatch = (e = null) => {
	const SpeechSDK = getSpeechSDK()
	if (e && e?.result?.reason == SpeechSDK.ResultReason.NoMatch) return true
}

export const isCanceled = (e = null) => {
	const SpeechSDK = getSpeechSDK()
	if (e && e?.reason == SpeechSDK.CancellationReason.Error) return true
}

// openai

// eslint-disable-next-line no-unused-vars
export const getOpenAiClient = (endpoint = null, apiKey = null) => {
	const { OpenAIClient, AzureKeyCredential } = require('@azure/openai')

	const client = new OpenAIClient(
		endpoint,
		new AzureKeyCredential(apiKey) // '<Azure API key>'
	)

	return client
}

const promptInstructions = `
Jako lekarz podsumuj opisowe wyniki medyczne po polsku, dostarczając wszechstronnego i 
precyzyjnego przeglądu stanu pacjenta. Podkreśl kluczowe obserwacje, wyniki 
diagnostyczne oraz wszelkie istotne dane z historii medycznej. Nie duplikuj tekstu. 
Dane nie obejmują całego pobytu. Nie jest to opis pełnego pobytu, dane nie kompletne. Upewnij się, 
że streszczenie odzwierciedla głębokie zrozumienie kontekstu medycznego i używa 
odpowiedniej terminologii medycznej dla precyzyjnej komunikacji wśród profesjonalistów 
służby zdrowia.

Zwróć tylko treść podsumowania.
`
export const initialPrompt = `
${promptInstructions}

@content
`
export const initialPromptInjectString = `@content`

export const fillPrompt = (value = null, promptPattern = initialPrompt) => {
	if (!value || value === '') return null
	return promptPattern.replaceAll(initialPromptInjectString, value)
}

export const modelsAvaliable = [
	'text-davinci-003',
	'gpt-35-turbo',
	'gpt-35-turbo-16k',
	'gpt-4',
	'gpt-4-32k',
]

export const modelTokenMaxPerRequest = {
	'text-davinci-003': 2048,
	'gpt-35-turbo': 4096,
	'gpt-35-turbo-16k': 16384,
	'gpt-4': 8192,
	'gpt-4-32k': 32768,
}

const errDeploymentTryOther = [
	`The model associated with the deployment is deprecated and no longer available for use. Please refer to the Azure OpenAI service documentation for more information.`,
	'The API deployment for this resource does not exist. If you created the deployment within the last 5 minutes, please wait a moment and try again.',
]

const fixResp = (resp) => {
	if (!resp) return resp

	const re = /^\s*[Pp]odsumowanie:?\s*/
	if (re.test(resp)) {
		resp = resp?.replace(re, '')
		console.debug(`[fixResp] Usunięto 'Podsumowanie:' z odpowiedzi`)
	}

	return resp
}

const MAX_RETRIES = 5

export const getCompletionsUniversal = async (
	client,
	deploymentName = null,
	prompt = [],
	options = {},
	triedModels = [],
	retries = 0
) => {
	const getCompletionsModels = ['text-davinci-003'],
		getChatCompletionsModels = [
			'gpt-35-turbo',
			'gpt-35-turbo-16k',
			'gpt-4',
			'gpt-4-32k',
		]

	// validate token limit
	const { maxTokens } = options || {}
	const tokenLimit = modelTokenMaxPerRequest?.[deploymentName] || null
	if (maxTokens && tokenLimit && maxTokens > tokenLimit)
		throw new Error(
			`[getCompletionsUniversal] Przekroczono limit tokenów dla modelu '${deploymentName}', ${maxTokens} > ${tokenLimit}`
		)

	let fn = null

	if (getCompletionsModels.includes(deploymentName)) {
		fn = getCompletions
	} else if (getChatCompletionsModels.includes(deploymentName)) {
		fn = getChatCompletions
	}

	if (!fn) {
		throw new Error(
			`[getCompletionsUniversal] Nieznany model '${deploymentName}'`
		)
	}

	console.debug(`[getCompletionsUniversal]`, {
		fn,
		deploymentName,
		prompt,
		options,
	})

	try {
		return await fn(client, deploymentName, prompt, options)
	} catch (e) {
		const err429CodeRegex = /Please retry after (\d*) seconds/
		// Requests to the ChatCompletions_Create Operation under Azure OpenAI API
		// version 2023-12-01-preview have exceeded token rate limit of your current
		// OpenAI S0 pricing tier. Please retry after 55 seconds. Please go here:
		// https://aka.ms/oai/quotaincrease if you would like to further increase
		// the default rate limit.

		if (err429CodeRegex.test(e.message) && retries < MAX_RETRIES) {
			// try again after timeout
			const timeout = parseInt(e.message.match(err429CodeRegex)[1])
			console.debug(
				`[getCompletionsUniversal] Przekroczono limit zapytań dla modelu '${deploymentName}', ${timeout} sekund do następnej próby. Próba ${
					retries + 1
				} z ${MAX_RETRIES}`
			)
			await new Promise((resolve) => setTimeout(resolve, timeout * 1000))
			return await getCompletionsUniversal(
				client,
				deploymentName,
				prompt,
				options,
				triedModels,
				retries + 1
			)
		} else if (errDeploymentTryOther.includes(e.message)) {
			// try for next model in modelsAvaliable
			let nextModel = null

			if (
				modelsAvaliable.indexOf(deploymentName) <
				modelsAvaliable.length - 1
			) {
				nextModel =
					modelsAvaliable[modelsAvaliable.indexOf(deploymentName) + 1]
			} else {
				nextModel = modelsAvaliable[0]
			}

			if (nextModel) {
				if (triedModels.includes(nextModel)) {
					throw new Error(
						`[getCompletionsUniversal] Wszystkie modele zostały sprawdzone i żaden z nich nie działa`
					)
				}

				triedModels.push(nextModel)

				console.debug(
					`[getCompletionsUniversal] Próba z modelem '${nextModel}'`
				)

				return await getCompletionsUniversal(
					client,
					nextModel,
					prompt,
					options,
					triedModels
				)
			}
		} else {
			throw e
		}
	}
}

export const getChatCompletions = async (
	client,
	deploymentName = null,
	prompt = [],
	options = {}
) => {
	const messages = [
		{ role: 'system', content: promptInstructions },
		{ role: 'user', content: prompt[0] },
	]

	const completions = await client.getChatCompletions(
		deploymentName,
		messages,
		options
	)

	return {
		...completions,
		response: fixResp(completions?.choices?.[0]?.message?.content),
	}
}

export const getCompletions = async (
	client,
	deploymentName = null,
	prompt = [],
	options = {}
) => {
	if (prompt && prompt.length > 0) prompt = prompt.map((e) => fillPrompt(e))

	const completions = await client.getCompletions(deploymentName, prompt, {
		maxTokens: 200,
		temperature: 0.2,
		...options,
	})

	return {
		...completions,
		response: fixResp(completions?.choices?.[0]?.text),
	}
}

export const genNote = (data = {}) => {
		const {
			treatments_completion,
			examinations_params,
			examinations_pending,
			drug_applications,
			examinations_lab,
			examinations_rad_completion,
			examinations_con_completion,
		} = data

		console.debug(`[genNote]`, data)

		const fixNewLines = (e) => e //e?.replaceAll('\n', '<br />')
		const makeHeader = (e) => makeParagraph(e) // `<h2>${e}</h2>`
		const makeList = (e) => e.join('') //`<ul>${e.join('')}</ul>`
		const makeParagraph = (e) => fixNewLines(e) + '\n' //`<p>${fixNewLines(e)}</p>`
		const makeListItem = (e) => makeParagraph(e) //`<li>${makeParagraph(e)}</li>`
		// const appendNewLineAfter = (e) => `${e}<br/>`

		let ret = ''

		if (treatments_completion || examinations_params) {
			ret += makeHeader('Stan pacjenta')

			if (treatments_completion)
				ret += makeParagraph(treatments_completion) + '\n'
			// else ret += makeParagraph(`Brak Obserwacji pielęgniarskich`)

			if (examinations_params)
				ret +=
					makeList(
						examinations_params.map((e) => makeListItem(e?.prompt))
					) + '\n'
			// else ret += makeParagraph(`Brak parametrów życiowych poza normą`)

			if (examinations_pending) {
				ret += makeHeader(`Zlecone`)
				ret += examinations_pending
					.map((e) => makeParagraph(e.prompt))
					.join('')
			}
			// else ret += makeParagraph(`Brak zleconych badań`)
		}
		// ret += makeHeader(`Leki`)

		if (drug_applications) ret += makeParagraph(drug_applications)
		// else ret += makeParagraph(`Podaż leków bez zmian`)

		if (examinations_lab || examinations_rad_completion) {
			ret += makeHeader(`Wyniki`)

			if (examinations_lab)
				ret += examinations_lab
					.map((e) => makeParagraph(e?.prompt))
					.join('')
			// else ret += makeParagraph(`Brak wyników badań laboratoryjnych`)

			if (examinations_rad_completion)
				ret += makeList(
					examinations_rad_completion.map((e) =>
						makeListItem(e?.completion?.response)
					)
				)
		}
		// else ret += makeParagraph(`Brak wyników badań obrazowych`)

		if (examinations_con_completion) {
			ret += makeHeader(`Konsultacje`)
			ret += makeList(
				examinations_con_completion.map((e) =>
					makeListItem(e?.completion?.response)
				)
			)
		}
		// else ret += makeParagraph(`Brak streszczeń z opisanych konsultacji`)

		return ret
	},
	compareDrugApplications = (a, b) =>
		a.units_count != b.units_count ||
		a.unit_name != b.unit_name ||
		a.frequency_name != b.frequency_name,
	mapDrugApplication = (drugApplication) => {
		const {
			application_date,
			application_way,
			frequency_name,
			name,
			units_count,
			unit_name,
		} = drugApplication

		//(2023-09-18 08:00) METYPRED 0,004 G, OP. 30 TABL. 8 mg
		const content = [
			`(${getFormattedDate(application_date) || '-'})`,
			name.replaceAll('\n', ' '),
			application_way,
			frequency_name,
			units_count,
			unit_name,
		].join(' ')

		return `${content || ''}`
	},
	getObj = (o) => o?._dataSync$?._value

export default getAuthSS
